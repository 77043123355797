import './style.scss';
import structure from '~/structure.json';

export default function () {
  const mainContainer = document.querySelector('.playground-overview') as HTMLElement;

  const containerLink = getListHtml();
  mainContainer.appendChild(containerLink);

  function getListHtml() {
    const content = document.createElement('div');
    content.classList.add('inner');

    structure.forEach((s) => {
      const wrapper = document.createElement('div');
      wrapper.classList.add('playground-item');
      wrapper.innerHTML = `<div class="header"><span class='title'>${s.title}</span><span class='author'>by ${s.author}</span></div>`;

      const linkList = document.createElement('ul');
      linkList.classList.add('links');
      wrapper.appendChild(linkList);

      s.childRoutes.forEach((cR) => {
        const imageUrl = new URL(
          `/src/playgrounds/${s.folder}/${cR.folder}/cover.webp`,
          import.meta.url
        ).href;
        const li = document.createElement('li');
        li.classList.add('listitem');
        li.innerHTML = `
          <a class='link' href='${cR.path}'>
            <figure class="figure">
              <img class='image' src='${imageUrl}' alt=''>
            </figure>
            <span class='title'>${cR.title}</span>
          </a>
          `;
        linkList.appendChild(li);
      });

      content.appendChild(wrapper);
    });
    return content;
  }
}
