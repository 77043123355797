import playgroundLayout from '~/layouts/playgrounds/layout.ts';
import getDataFromRoute from '~/utils/getDataFromRoute.ts';

export async function getPlaygroundPage(path: string) {
  const scripts = [];
  const folder = getPlaygroundFromRoute(path);
  const subFolder = getVersionFromRoute(path);

  const layout = playgroundLayout(
    (await import(`~/playgrounds/${folder}/${subFolder}/index.html?raw`)).default
  );
  scripts.push(layout.script);

  const data = getDataFromRoute(folder, subFolder);
  if (data?.version?.typed) {
    scripts.push((await import(`~/playgrounds/${folder}/${subFolder}/main.ts`)).default);
  } else {
    scripts.push((await import(`~/playgrounds/${folder}/${subFolder}/main.js`)).default);
  }

  return {
    html: layout.getHtml(),
    scripts: scripts,
  };
}

function getVersionFromRoute(r: string) {
  const split = getRouteSplit(r);
  return split[1];
}

function getPlaygroundFromRoute(r: string) {
  const split = getRouteSplit(r);
  return split[0];
}

function getRouteSplit(r: string) {
  return r.split('/').filter((i) => i !== '' && i !== 'm') ?? [];
}
