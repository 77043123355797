export default function usePath() {
  const path = {
    playground: getPlaygroundFromRoute(location.pathname),
    version: getVersionFromRoute(location.pathname),
  };

  return path;

  function getVersionFromRoute(r: string) {
    const split = getRouteSplit(r);
    return split[1];
  }

  function getPlaygroundFromRoute(r: string) {
    const split = getRouteSplit(r);
    return split[0];
  }

  function getRouteSplit(r: string) {
    return r.split('/').filter((i) => i !== '' && i !== 'm') ?? [];
  }
}
