import structure from '~/structure.json';

export default function getDataFromRoute(folder: string, playground: string) {
  const folderData = structure.find((i) => i.folder === folder);

  return {
    playground: folderData,
    version: folderData?.childRoutes.find((i) => i.folder === playground),
  };
}
