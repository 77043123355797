import './style.scss';
import routes from '~/routes.json';
import { page404 } from '~/views/404/page-404.ts';
import { pageOverview } from '~/views/overview/page-overview.ts';
import { getPlaygroundPage } from '~/playgrounds/getPlaygroundPage.ts';

const app = document.querySelector<HTMLDivElement>('#app') as HTMLElement;

window.addEventListener('hashchange', setRoute);
window.addEventListener('load', setRoute);

async function setRoute() {
  const path = parseRequestPath();
  const { html, scripts } = await getPage(path);

  return runPage(html, scripts);
}

function getPage(path: string) {
  if (checkValidPlaygroundRoute(path)) {
    return getPlaygroundPage(path);
  } else if (path === '/') {
    return pageOverview;
  }
  return page404;
}

function runPage(html: string, scripts: (() => void)[]) {
  app.innerHTML = html;
  scripts.forEach((s) => s());
}

function parseRequestPath() {
  return location.pathname;
}

function checkValidPlaygroundRoute(p: string) {
  return routes.includes(p) && p.split('/').length > 2;
}
