import './style.scss';
import Layout from '~/layouts/playgrounds/layout.html?raw';
import usePath from '~/utils/usePath.ts';
import routes from '~/routes.json';
import getDataFromRoute from '~/utils/getDataFromRoute.ts';
import { isMinimalisticView } from '~/utils/isMinimalisticView.ts';

export default function layout(html: string) {
  return {
    getHtml,
    script,
  };

  function getHtml() {
    return Layout.replace('<slot/>', html);
  }

  function script() {
    const status = {
      isHeaderVisible: true,
    };
    const path = usePath();
    const data = getDataFromRoute(path.playground, path.version);

    const elements = {
      header: document.querySelector('[data-playground-controls]') as HTMLElement,
      buttonToggleVisibility: document.querySelector('[data-btn-visibility]') as HTMLButtonElement,
      linkPrev: document.querySelector('[data-link-prev]') as HTMLLinkElement,
      linkClose: document.querySelector('[data-link-close]') as HTMLLinkElement,
      linkNext: document.querySelector('[data-link-next]') as HTMLLinkElement,
      titlePlayground: document.querySelector('[data-title-playground]') as HTMLParagraphElement,
      titleVersion: document.querySelector('[data-title-version]') as HTMLParagraphElement,
    };

    /* setup */
    /* if is in minimalistic view -> hide all controls */
    if (isMinimalisticView()) {
      elements.header.remove();
      return;
    }

    /* else -> regular view */
    setHeaderVisibility(status.isHeaderVisible);

    const linkNextPlayground = getNextPlaygroundPath();

    if (linkNextPlayground) {
      elements.linkNext.href = linkNextPlayground;
    } else {
      elements.linkNext.classList.add('-hidden');
    }

    const linkPrevPlayground = getPrevPlaygroundPath();
    if (linkPrevPlayground) {
      elements.linkPrev.href = linkPrevPlayground;
    } else {
      elements.linkPrev.classList.add('-hidden');
    }

    if (data && data.playground) {
      elements.titlePlayground.innerHTML = data.playground.title;
    }

    if (data && data.version) {
      elements.titleVersion.innerHTML = data.version.title;
    }

    /*  events */
    elements.buttonToggleVisibility.addEventListener('click', () => {
      toggleControlsVisibility();
    });

    /* methods */
    function toggleControlsVisibility() {
      status.isHeaderVisible = !status.isHeaderVisible;
      setHeaderVisibility(status.isHeaderVisible);
    }

    function setHeaderVisibility(visible: boolean) {
      if (visible) {
        elements.header.classList.add('-visible');
        elements.buttonToggleVisibility.classList.add('-hide');
      } else {
        elements.header.classList.remove('-visible');
        elements.buttonToggleVisibility.classList.remove('-hide');
      }
    }

    function getNextPlaygroundPath() {
      const currentPath = location.pathname;
      const filteredRoutes = routes.filter((r) => !r.includes('/m/'));
      const i = filteredRoutes.indexOf(currentPath) + 1;
      return filteredRoutes[i] ?? null;
    }

    function getPrevPlaygroundPath() {
      const currentPath = location.pathname;
      const filteredRoutes = routes.filter((r) => !r.includes('/m/'));
      const i = filteredRoutes.indexOf(currentPath) - 1;
      return filteredRoutes[i] ?? null;
    }
  }
}
